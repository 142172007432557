import { ReactiveObject } from "./ReactiveObject";

export const isWakeLockApiAvailable = (): boolean => {
	return "wakeLock" in navigator;
};

export const enableWakeLock = async (): Promise<
	() => void
> => {
	
	if (!isWakeLockApiAvailable()) {
		return () => {};
	}

	const wakeLock = await (navigator as any).wakeLock.request("screen");

	return () => {
		wakeLock.release();
	};
}

export interface IWakeLockApi{
	active: boolean;
}

export class WakeLockApi extends ReactiveObject<
	IWakeLockApi
>{
	private _wakeLock: any;
	private _clearReleaseListener: () => void = () => {};
	constructor(){
		super({
			active: false,
		});
	}

	async enable(): Promise<void>{
		if (!isWakeLockApiAvailable()) {
			return;
		}

		const wakeLock = await (navigator as any).wakeLock.request("screen");
		this._wakeLock = wakeLock;
		this.set({
			active: true,
		});

		const onRelease = () => {
			this.set({
				active: false,
			});
		}

		wakeLock.addEventListener("release", onRelease);

		this._clearReleaseListener = () => {
			wakeLock.removeEventListener("release", onRelease);
		}
	}

	async disable(): Promise<void>{
		if (!isWakeLockApiAvailable()) {
			return;
		}

		this._wakeLock?.release();
		this._clearReleaseListener?.();
		this._wakeLock = undefined;
		this.set({
			active: false,
		});

		this._clearReleaseListener = () => {};
	}
}