<script lang="ts">
  import LazyLoad from "@components/LazyLoad/LazyLoad.svelte";
  import RoomViewPlaceholder from "@components/RoomView/Placeholder/RoomViewPlaceholder.svelte";
  import type { SvelteComponentTyped } from "svelte";
  import { Route, Router } from "svelte-navigator";
  import NotFound from "./NotFound.svelte";
  import RouteGuard from "./RouteGuard.svelte";

  interface IRoute {
    path: string;
    component: () => Promise<any>;
    guard?: (url: URL) => Promise<boolean> | boolean;
    routeProps?: {
      [key: string]: any;
    };
    componentProps?: {
      [key: string]: any;
    };
    condition?: (url: URL) => Promise<boolean> | boolean;
    containerComponent?: () => Promise<any>;
    containerComponentProps?: {
      [key: string]: any;
    };
    blockedViewComponent?: typeof SvelteComponentTyped<any, any, any>;
    loaderComponent?: typeof SvelteComponentTyped<any, any, any>;
  }

  const isLoggedIn = async (): Promise<boolean> => {
    try {
      return true;
      // const response = await fetch("/api/isUnlocked", {
      //   method: "GET",
      //   credentials: "include",
      //   headers: {
      //     "Content-Type": "application/json",
      //   },
      // });

      // const data = await response.json();
      // return !!data?.success;
    } catch (err) {
      console.error(err);
    }

    return false;
  };

  const notLoggedIn = async (): Promise<boolean> => {
    return await new Promise((resolve) => {
      setTimeout(() => {
        resolve(false);
      }, 1000);
    });
  };

  const checkValidRoomId = (url: URL) => {
    const roomId = url.pathname.replace("/", "");
    const regex = /^[a-z0-9A-Z]{3}-[a-z0-9A-Z]{3}-[a-z0-9A-Z]{3}$/;
    const result = regex.test(roomId);

    console.log("checkValidRoomId", result, roomId);
    return result;
  };

  const routes: IRoute[] = [
    {
      path: "/",
      component: () => import("./RandomRoom.svelte"),
      loaderComponent: RoomViewPlaceholder,
    },
    {
      path: "/closed",
      component: () => import("./Closed.svelte"),
      // guard: isLoggedIn,
    },
    {
      path: "/:id",
      guard: checkValidRoomId,
      blockedViewComponent: NotFound,
      component: () => import("./Room.svelte"),
      loaderComponent: RoomViewPlaceholder,
    },
    {
      path: "*",
      component: () => import("./NotFound.svelte"),
      // guard: isLoggedIn,
    },
  ];
</script>

<Router>
  {#each routes as route}
    <Route
      let:params
      let:location
      let:navigate
      path={route.path}
      {...route.routeProps ?? {}}
    >
      <RouteGuard
        condition={route.guard}
        BlockedViewComponent={route.blockedViewComponent}
      >
        <LazyLoad let:component loader={route.component} loaderComponent={route.loaderComponent}>
          <svelte:component
            this={component}
            {params}
            {location}
            {navigate}
            {...route.componentProps ?? {}}
          />
        </LazyLoad>
      </RouteGuard>
    </Route>
  {/each}
</Router>
