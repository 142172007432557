<script lang="ts">
	export let value: string;
	export let placeholder: string;

  export let languageDirection: "rtl" | "ltr" = "ltr"; 
</script>

<input
  on:keydown
  placeholder={placeholder}
  bind:value
  type="text"
  style:direction={languageDirection}
/>

<style lang="postcss">
  input{
    @apply input w-full h-full rounded-xl bg-neutral;
  }
</style>