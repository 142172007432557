<script lang="ts">
    import { wakeLockApi } from "@common/basicStores";
    import { onDestroy, onMount } from "svelte";


	onMount(() => {
		wakeLockApi.enable();
	})
	

	onDestroy(() => {
		wakeLockApi.disable();
	})
</script>